.App {
  font-family: 'Comfortaa', cursive;
}

.suggestion {
  font-family: 'Comfortaa', cursive;
}

.suggestion__form {
  position: fixed;
  bottom: 16px;
  width: 100%;
  text-align: center;
}

.prediction__desc {
  padding: 16px;
  text-align: center;
}

.rate__item {
  margin: 5px;
}

.rate__item:hover {
  cursor: pointer;
}  
